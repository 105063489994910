import { instructions } from "@/constants/promps";
import { BOT_GRADIENT_DIRECTION } from "@/schemas/bot-ui-schema";
import { ActionReturn } from "@/types";
import { UserAccount } from "@prisma/client";
import { clsx, type ClassValue } from "clsx";
import { colord } from "colord";
import { differenceInDays, format, formatRelative } from "date-fns";
import Stripe from "stripe";
import { twMerge } from "tailwind-merge";
import { animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import {
  MINIO_BOT_AVATAR_BUCKET,
  MINIO_BOT_ICON_BUCKET,
  MINIO_USER_BUCKET,
} from "@/constants";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function numberFormat(num: number) {
  return new Intl.NumberFormat().format(num);
}

export function dateFormat(
  date: string | Date,
  formatString: string = "dd/MM/yyyy",
) {
  return format(date instanceof Date ? date.toISOString() : date, formatString);
}

export async function getBlurDataURL(url: string | null) {
  if (!url) {
    return "data:image/webp;base64,AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=";
  }
  try {
    const response = await fetch(
      `https://wsrv.nl/?url=${url}&w=50&h=50&blur=5`,
    );
    const buffer = await response.arrayBuffer();
    const base64 = Buffer.from(buffer).toString("base64");

    return `data:image/png;base64,${base64}`;
  } catch (error) {
    return "data:image/webp;base64,AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=";
  }
}

export async function actionWrapper<T>(action: Promise<ActionReturn<T>>) {
  const result = await action;

  if (!result.success) {
    throw result.errors;
  }

  return result.data;
}

// export async function checkOrganizationAccess(
//   orgId: string,
// ) {
//   const session = await auth();
//   const members = await prisma.membership.findMany({
//     where: {
//       id: orgId,
//     },
//   });

//   const member = members.find((m) => m.user_id === session?.user?.id);
//   const role = member?.role;

//   if(!member){
//     throw new Error("You are not a member of this organization");
//   }

//   if(role === Role.OWNER || role === Role.ADMIN){
//     throw new Error("You do not have permission to access this organization");
//   }

// }

export function generateChatBotName() {
  const name = uniqueNamesGenerator({
    dictionaries: [colors, animals],
    separator: "",
    style: "capital",
  });

  const numberForName = randomIntFromInterval(1, 1000);

  return name.trim() + numberForName;
}

export function generateChatBotId() {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const letter1 = letters[Math.floor(Math.random() * letters.length)];
  const letter2 = letters[Math.floor(Math.random() * letters.length)];
  const letter3 = letters[Math.floor(Math.random() * letters.length)];

  const randomNumber1 = randomIntFromInterval(10, 99);
  const randomNumber2 = randomIntFromInterval(10, 99);

  return "Chat-" + randomNumber1 + letter1 + letter2 + letter3 + randomNumber2;
}

export function getUserProfilePictureLink(path: string) {
  if (
    !path ||
    path?.startsWith("blob:") ||
    path?.startsWith("http") ||
    path?.startsWith("/api")
  ) {
    return path;
  }
  return (
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/storage/${MINIO_USER_BUCKET}/` +
    path
  );
}

export function getMinioLink(bucket: string, path: string) {
  if (
    !path ||
    path?.startsWith("blob:") ||
    path?.startsWith("http") ||
    path?.startsWith("/api")
  ) {
    return path;
  }
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/storage/${bucket}/${path}`;
}

export function getBotAvatarPicturePath(path: string) {
  if (
    !path ||
    path?.startsWith("blob:") ||
    path.startsWith("http") ||
    path.startsWith("/api")
  ) {
    return path;
  }
  return (
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/storage/${MINIO_BOT_AVATAR_BUCKET}/` +
    path
  );
}

export function getBotIconPicturePath(path: string) {
  if (
    !path ||
    path?.startsWith("blob:") ||
    path.startsWith("http") ||
    path.startsWith("/api")
  ) {
    return path;
  }
  return (
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/storage/${MINIO_BOT_ICON_BUCKET}/` +
    path
  );
}

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function generateBotAvatarName(botId: string) {
  return `chatbot-avatar-${botId}.png`;
}

export function generateBotIconName(botId: string) {
  return `chatbot-icon-${botId}.png`;
}

export function wordCount(text: string): number {
  return text.replaceAll(`\n`, " ").split(" ").length;
}

export function getExtensionFromMimeType(mimeType: string) {
  const mimeTypes: Record<string, string> = {
    "audio/aac": "aac",
    "application/x-abiword": "abw",
    "application/x-freearc": "arc",
    "video/x-msvideo": "avi",
    "application/vnd.amazon.ebook": "azw",
    "application/octet-stream": "bin",
    "image/bmp": "bmp",
    "application/x-bzip": "bz",
    "application/x-bzip2": "bz2",
    "application/x-cdf": "cda",
    "application/x-csh": "csh",
    "text/css": "css",
    "text/csv": "csv",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "application/vnd.ms-fontobject": "eot",
    "application/epub+zip": "epub",
    "application/gzip": "gz",
    "image/gif": "gif",
    "text/html": "html",
    "image/vnd.microsoft.icon": "ico",
    "text/calendar": "ics",
    "application/java-archive": "jar",
    "image/jpeg": "jpg",
    "text/javascript": "js",
    "application/json": "json",
    "application/ld+json": "jsonld",
    "audio/mpeg": "mp3",
    "video/mp4": "mp4",
    "video/mpeg": "mpeg",
    "application/vnd.apple.installer+xml": "mpkg",
    "application/vnd.oasis.opendocument.presentation": "odp",
    "application/vnd.oasis.opendocument.spreadsheet": "ods",
    "application/vnd.oasis.opendocument.text": "odt",
    "audio/ogg": "oga",
    "video/ogg": "ogv",
    "application/ogg": "ogx",
    "audio/opus": "opus",
    "font/otf": "otf",
    "image/png": "png",
    "application/pdf": "pdf",
    "application/x-httpd-php": "php",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "pptx",
    "application/vnd.rar": "rar",
    "application/rtf": "rtf",
    "application/x-sh": "sh",
    "image/svg+xml": "svg",
    "application/x-shockwave-flash": "swf",
    "application/x-tar": "tar",
    "image/tiff": "tiff",
    "video/mp2t": "ts",
    "font/ttf": "ttf",
    "text/plain": "txt",
    "application/vnd.visio": "vsd",
    "audio/wav": "wav",
    "audio/webm": "weba",
    "video/webm": "webm",
    "image/webp": "webp",
    "font/woff": "woff",
    "font/woff2": "woff2",
    "application/xhtml+xml": "xhtml",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/xml": "xml",
    "application/vnd.mozilla.xul+xml": "xul",
    "application/zip": "zip",
    "video/3gpp": "3gp",
    "video/3gpp2": "3g2",
    "application/x-7z-compressed": "7z",
  };

  return mimeTypes[mimeType] ?? undefined;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getErrorMessage(error: any): string {
  const isString = typeof error === "string";
  const isErrorInstance = error instanceof Error;

  if (isString) return error;
  if (isErrorInstance) return error.message;
  if ("fieldErrors" in error) {
    return Object.values(error.fieldErrors ?? {})
      .flatMap((x) => x)
      .join(", ");
  }

  return "Something went wrong, please try again";
}

export function prepareFormData(formData: FormData) {
  const isNumberRegex = /^\d+$/;
  return Object.fromEntries(
    Object.entries(Object.fromEntries(formData)).map(([key, value]) => {
      if (
        value.toString().startsWith("[") ||
        value.toString().startsWith("{")
      ) {
        try {
          return [key, JSON.parse(value.toString())];
        } catch {}
      }

      if (isNumberRegex.test(value.toString())) {
        return [key, Number(value)];
      }

      if (["true", "false"].includes(value.toString().toLowerCase())) {
        return [key, value.toString().toLowerCase() === "true"];
      }

      return [key, value];
    }),
  );
}
export function countAlphanumericCharacters(text: string): number {
  const alphanumericCharacters = text.match(/[a-zA-Z0-9]/g);

  return alphanumericCharacters ? alphanumericCharacters.length : 0;
}

export function startCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/[_-]+/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function parseMetadata(metadata: Record<string, string>) {
  return Object.entries(metadata).map(([key, value]) => {
    let _value;
    try {
      _value = JSON.parse(value);
    } catch {
      _value = value;
    }
    return {
      [key]: _value,
    };
  });
}

export function canDowngradePlan(
  account: UserAccount,
  targetPrice: Stripe.Price,
) {
  type Metadata = {
    botLimit: number;
    orgLimit: number;
    memberLimit: number;
  };
  const productMetadata = (targetPrice.product as Stripe.Product)
    .metadata as unknown as Metadata;

  const currentLimits = {
    chatbotCount: account.chatbot_count,
    organizationCount: account.organization_count,
    teamMemberCount: account.team_member_count,
  };

  const planLimits = {
    chatbotCount: +productMetadata.botLimit,
    organizationCount: +productMetadata.orgLimit,
    teamMemberCount: +productMetadata.memberLimit,
  };

  const limits = {
    chatbotCount: productMetadata.botLimit >= currentLimits.chatbotCount,
    organizationCount:
      productMetadata.orgLimit >= currentLimits.organizationCount,
    teamMemberCount:
      productMetadata.memberLimit >= currentLimits.teamMemberCount,
  };

  const values = Object.values(limits);
  const canDowngrade = values.every((value) => value);

  return {
    currentLimits,
    planLimits,
    canDowngrade,
  };
}

export function moneyFormat(amount: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}
/**
 * Represents the data for a heading element.
 */
export interface HeadingData {
  /**
   * The level of the heading element.
   */
  level: number;
  /**
   * The text content of the heading element.
   */
  text: string;
  /**
   * The slug for the heading element.
   */
  slug: string;
}

/**
 * Represents the data for a heading node.
 */
export interface HeadingNode extends HeadingData {
  /**
   * The children of the heading node.
   */
  children: HeadingNode[];
}

/**
 * Serializes an array of heading data into a tree structure of heading nodes.
 * @param headings The array of heading data to serialize.
 * @returns The tree structure of heading nodes.
 */
export function serializeHeadings(headings: HeadingData[]): HeadingNode[] {
  // Initialize an empty array to hold the root nodes of the tree.
  const tree: HeadingNode[] = [];
  // Initialize an empty array to hold the nodes that are currently open.
  const stack: HeadingNode[] = [];

  // Iterate over each heading in the array.
  for (const heading of headings) {
    // Create a new node for the current heading.
    const node: HeadingNode = {
      level: heading.level,
      text: heading.text,
      slug: heading.slug,
      children: [],
    };

    // Close any nodes that are deeper than the current node.
    while (stack.length > 0 && stack[stack.length - 1].level >= node.level) {
      stack.pop();
    }

    // Add the current node to the tree.
    if (stack.length === 0) {
      tree.push(node);
    } else {
      stack[stack.length - 1].children.push(node);
    }

    // Add the current node to the stack.
    stack.push(node);
  }

  // Return the root nodes of the tree.
  return tree;
}

export type TableOfContent = TableOfContentItem & {
  children: TableOfContentItem[];
};

export type TableOfContentItem = {
  slug: string;
  text: string;
};

export const generateToc = (content: string): TableOfContent[] => {
  return content.split("\n").reduce((acc: TableOfContent[], line) => {
    const match = line.match(/^(#{1,3})\s/);

    if (match) {
      const level = match[1].length.toString();
      const slug = line
        .replace(/^#{1,3}\s*/, "")
        .trim()
        .replace(/\s/g, "-");
      const text = line.replace(/^#{1,3}\s*/, "").trim();
      const item: TableOfContent = {
        slug,
        text,
        children: [],
      };

      if (level === "2") {
        acc.push(item);
      } else if (level === "3" && acc.length > 0) {
        acc[acc.length - 1].children.push(item);
      }
    }

    return acc;
  }, []);
};

export const delay = (milliseconds: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

export function textToFile(name: string, content: string, type: string) {
  const blob = new Blob([content], { type });
  return new File([blob], name, { type });
}

export function removeLastSlash(string: string) {
  if (string.endsWith("/")) {
    return string.slice(0, -1);
  }

  return string;
}

export function getColor(
  color: string,
  darkenValue: number,
  lightenValue: number,
) {
  const col = colord(color);
  if (col.isDark()) {
    return col.lighten(lightenValue).toHex();
  } else {
    return col.darken(darkenValue).toHex();
  }
}

type GradientDirection = (typeof BOT_GRADIENT_DIRECTION)[number];

interface GradientStyleParams {
  fromColor: string;
  toColor: string;
  direction: GradientDirection;
  linear: boolean;
  stop: number;
}

export function generateGradientStyle(req: GradientStyleParams): string {
  const { fromColor, toColor, direction, linear, stop } = req;
  const linearDirectionMap: Record<GradientDirection, string> = {
    TOP: "to bottom",
    BOTTOM: "to top",
    LEFT: "to right",
    RIGHT: "to left",
    TOP_LEFT: "to bottom right",
    TOP_RIGHT: "to bottom left",
    BOTTOM_LEFT: "to top right",
    BOTTOM_RIGHT: "to top left",
  };

  if (linear) {
    const _direction = linearDirectionMap[direction];
    return `linear-gradient(${_direction}, ${fromColor} ${stop}%, ${toColor} 100%)`;
  } else {
    return `radial-gradient(${fromColor} ${stop}%, ${toColor} 100%)`;
  }
}
export function isIOSChrome() {
  if (typeof window === "undefined") return false;
  return /CriOS/.test(window.navigator.userAgent);
}

export function getMimeType() {
  return getBrowserName() === "Safari" || isIOSChrome() ? "mp4" : "webm";
  /*
  let mimeType = "audio/webm";
  const browserName = getBrowserName();
  if (browserName === "Safari" || isIOSChrome()) mimeType = "audio/mp4";
  return mimeType;
   */
}

export function getMediaSource(): MediaSource | null {
  if (typeof window === "undefined") return null;

  if ("MediaSource" in window) {
    return new window.MediaSource();
  }

  if ("ManagedMediaSource" in window) {
    // @ts-ignore
    return new window.ManagedMediaSource();
  }

  return null;
}


export const getBrowserName = () => {
  if (typeof navigator === "undefined") return "Unknown";

  const userAgent = navigator.userAgent;

  if (
    /chrome|crios|crmo/i.test(userAgent) &&
    !/edg|edge|opr|opera/i.test(userAgent)
  ) {
    return "Chrome";
  } else if (/firefox|fxios/i.test(userAgent)) {
    return "Firefox";
  } else if (
    /safari/i.test(userAgent) &&
    !/chrome|crios|crmo|opr|opera|edg|edge/i.test(userAgent)
  ) {
    return "Safari";
  } else if (/edg|edge/i.test(userAgent)) {
    return "Edge";
  } else {
    return "Unknown";
  }
};
export function lightenColor(hex: string, percent: number) {
  // Convert hex to RGB
  const num = parseInt(hex.slice(1), 16);
  const r = (num >> 16) + Math.round(255 * (percent / 100));
  const g = ((num >> 8) & 0x00ff) + Math.round(255 * (percent / 100));
  const b = (num & 0x0000ff) + Math.round(255 * (percent / 100));

  // Ensure values are within bounds
  const newR = (r > 255 ? 255 : r < 0 ? 0 : r).toString(16).padStart(2, "0");
  const newG = (g > 255 ? 255 : g < 0 ? 0 : g).toString(16).padStart(2, "0");
  const newB = (b > 255 ? 255 : b < 0 ? 0 : b).toString(16).padStart(2, "0");

  // Convert RGB back to hex
  return `#${newR}${newG}${newB}`;
}

export const getAudioCapabilities = () => {
  if (typeof window === "undefined")
    return { playback: false, recording: false };
  // Check for AudioContext support
  const audioContextSupport = !!(
    // @ts-ignore
    (window.AudioContext || window.webkitAudioContext)
  );
  // Check for MediaDevices and getUserMedia support
  const mediaDevicesSupport = !!(
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia
  );
  return {
    playback: audioContextSupport,
    recording: mediaDevicesSupport,
  };
};

export function findInstruction(instruction: string) {
  return instructions.find((item) => {
    return item.value.trim() === instruction.trim();
  });
}

export function formatRelativeTime(
  date1: Date | string,
  date2: Date | string,
): string {
  const diff = differenceInDays(new Date(date1), new Date(date2));
  if (diff === 0) {
    return format(new Date(date1), "h:mm a");
  }

  return formatRelative(new Date(date1), new Date(date2));
}

export function containsArabic(text: string) {
  const arabicRegex =
    /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return arabicRegex.test(text);
}

export function containsHebrew(text: string) {
  const hebrewRegex = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
  return hebrewRegex.test(text);
}

export function getNamedFile(file: File, newName: string) {
  return new File([file], newName, {
    type: file.type,
  });
}

export function setCookie(
  name: string,
  value: string,
  minutesToExpire: number = 30,
) {
  if (typeof window === "undefined") {
    return;
  }
  const d = new Date();
  d.setTime(d.getTime() + minutesToExpire * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name: string) {
  if (typeof window === "undefined") {
    return null;
  }

  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}

export function removeCookie(name: string | string[]) {
  if (typeof window === "undefined") {
    return null;
  }

  const keys = Array.isArray(name) ? name : [name];
  keys.forEach((key) => {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  });
}
